var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-status"},[(_vm.boxOpened.isOpen && false)?_c('span',{staticStyle:{"font-size":"24px","margin-top":"1.5rem"},domProps:{"innerHTML":_vm._s('AppCheckin - SmsCheckin - CardCheckin - NfcCheckin')}}):_vm._e(),(_vm.boxOpened.isOpen)?_c('div',{staticClass:"description"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t(`outdoor.index.combi.headline.${_vm.box.hasAppCheckin}_${_vm.box.hasSmsCheckin}_${_vm.box.hasCardCheckin}_${_vm.box.hasNfcCheckin}`))}})]):_vm._e(),(_vm.boxOpened.status === 'CLOSED')?_c('div',{staticClass:"description"},[_c('span',{domProps:{"innerHTML":_vm._s(`<strong>Wir haben gerade geschlossen!</strong>Bitte besuchen Sie unswährend <br>unserer Öffnungszeiten:`)}})]):_vm._e(),(_vm.boxOpened.status === 'OPEN'
      && _vm.fakeHoursManned && Object.keys(_vm.fakeHoursManned).length > 0)?_c('div',{staticClass:"opening-hours opened"},[(_vm.fakeHours && Object.keys(_vm.fakeHours).length > 0
        && _vm.fakeHoursManned && Object.keys(_vm.fakeHoursManned).length > 0)?_c('div',{key:`weekday-day--1`,staticClass:"hours hours-headline"},[_vm._v(" Mit Verkaufspersonal ")]):_vm._e(),_vm._l((_vm.fakeHoursManned),function(days,weekday){return _c('div',{key:`weekday-day-${weekday}`,staticClass:"hours"},[_c('span',{staticClass:"weekday-name"},[_vm._v(_vm._s(_vm.weekdayName(Number(weekday))))]),_c('span',{staticClass:"two-po"},[_vm._v(":")]),_vm._l((days),function(day,index){return [(_vm.$date(day.timeFrom, 'HH:mm:ss').format('HH:mm') === '00:00' &&
            _vm.$date(day.timeTo, 'HH:mm:ss').format('HH:mm') === '00:00')?_c('span',{key:`hour-${day.id}`},[_c('span',{staticClass:"without-employee"},[_vm._v("Ohne Personal")])]):_c('span',{key:`hour-else-${day.id}`},[_c('span',{class:index === 0 ? 'first-ho' : 'ho'},[_vm._v(" "+_vm._s(_vm.$date(day.timeFrom, 'HH:mm:ss').format('HH:mm'))+" - "+_vm._s(_vm.$date(day.timeTo, 'HH:mm:ss').format('HH:mm')))]),(index < days.length - 1)?_c('br'):_vm._e()])]})],2)})],2):_vm._e(),(_vm.boxOpened.status === 'OPEN' && _vm.fakeHours && Object.keys(_vm.fakeHours).length > 0)?_c('div',{class:[
      'opening-hours opened',
      { 'opened-second' : _vm.fakeHoursManned && Object.keys(_vm.fakeHoursManned).length > 0 }
    ]},[(_vm.fakeHours && Object.keys(_vm.fakeHours).length > 0
        && _vm.fakeHoursManned && Object.keys(_vm.fakeHoursManned).length > 0)?_c('div',{key:`weekday-day--1`,staticClass:"hours hours-headline"},[_vm._v(" Ohne Verkaufspersonal ")]):_vm._e(),_vm._l((_vm.fakeHours),function(days,weekday){return _c('div',{key:`weekday-day-${weekday}`,staticClass:"hours"},[_c('span',{staticClass:"weekday-name"},[_vm._v(_vm._s(_vm.weekdayName(Number(weekday))))]),_c('span',{staticClass:"two-po"},[_vm._v(":")]),_vm._l((days),function(day,index){return [(_vm.$date(day.timeFrom, 'HH:mm:ss').format('HH:mm') === '00:00' &&
            _vm.$date(day.timeTo, 'HH:mm:ss').format('HH:mm') === '00:00')?_c('span',{key:`hour-${day.id}`},[_c('span',{staticClass:"without-employee"},[_vm._v("Ohne Personal")])]):_c('span',{key:`hour-else-${day.id}`},[_c('span',{class:index === 0 ? 'first-ho' : 'ho'},[_vm._v(" "+_vm._s(_vm.$date(day.timeFrom, 'HH:mm:ss').format('HH:mm'))+" - "+_vm._s(_vm.$date(day.timeTo, 'HH:mm:ss').format('HH:mm')))]),(index < days.length - 1)?_c('br'):_vm._e()])]})],2)})],2):_vm._e(),(_vm.boxOpened.status === 'OPEN' && _vm.fakeHours && Object.keys(_vm.fakeHours).length === 0 && _vm.fakeHoursManned && Object.keys(_vm.fakeHoursManned).length === 0)?_c('div',{staticClass:"opening-hours opened"},_vm._l((_vm.boxOpeningHoursGrouped),function(days,weekday){return _c('div',{key:`weekday-day-${weekday}`,staticClass:"hours"},[_c('span',{staticClass:"weekday-name"},[_vm._v(_vm._s(_vm.weekdayName(Number(weekday))))]),_c('span',{staticClass:"two-po"},[_vm._v(":")]),_vm._l((days),function(day,index){return [(_vm.$date(day.timeFrom, 'HH:mm:ss').format('HH:mm') === '00:00' &&
                    _vm.$date(day.timeTo, 'HH:mm:ss').format('HH:mm') === '00:00')?_c('span',{key:day.id},[_c('span',{staticClass:"without-employee"},[_vm._v("Ohne Personal")])]):_c('span',{key:`else-${day.id}`},[_c('span',{class:index === 0 ? 'first-ho' : 'ho'},[_vm._v(" "+_vm._s(_vm.$date(day.timeFrom, 'HH:mm:ss').format('HH:mm'))+" - "+_vm._s(_vm.$date(day.timeTo, 'HH:mm:ss').format('HH:mm')))]),(index < days.length - 1)?_c('br'):_vm._e()])]})],2)}),0):_vm._e(),(_vm.boxOpened.status === 'CLOSED'
    && _vm.fakeHoursManned && Object.keys(_vm.fakeHoursManned).length > 0)?_c('div',{staticClass:"closed"},[_c('div',{staticClass:"opening-hours"},[(_vm.fakeHours && _vm.fakeHours.length > 0
          && _vm.fakeHoursManned && Object.keys(_vm.fakeHoursManned).length > 0)?_c('div',{key:`weekday-day--1`,staticClass:"hours hours-headline"},[_vm._v(" Mit Verkaufspersonal ")]):_vm._e(),_vm._l((_vm.fakeHoursManned),function(days,weekday){return _c('div',{key:`weekday-day-${weekday}`,staticClass:"hours"},[_c('span',{staticClass:"weekday-name"},[_vm._v(_vm._s(_vm.weekdayName(Number(weekday))))]),_c('span',{staticClass:"two-po"},[_vm._v(":")]),_vm._l((days),function(day,index){return [(_vm.$date(day.timeFrom, 'HH:mm:ss').format('HH:mm') === '00:00' &&
              _vm.$date(day.timeTo, 'HH:mm:ss').format('HH:mm') === '00:00')?_c('span',{key:`hour-${day.id}`},[_c('span',{staticClass:"without-employee"},[_vm._v("Ohne Personal")])]):_c('span',{key:`hour-else-${day.id}`},[_c('span',{class:index === 0 ? 'first-ho' : 'ho'},[_vm._v(" "+_vm._s(_vm.$date(day.timeFrom, 'HH:mm:ss').format('HH:mm'))+" - "+_vm._s(_vm.$date(day.timeTo, 'HH:mm:ss').format('HH:mm')))]),(index < days.length - 1)?_c('br'):_vm._e()])]})],2)})],2)]):_vm._e(),(_vm.boxOpened.status === 'CLOSED'
    && _vm.fakeHours && Object.keys(_vm.fakeHours).length > 0)?_c('div',{class:[
      'closed',
      { 'closed-second' : _vm.fakeHoursManned && Object.keys(_vm.fakeHoursManned).length > 0 }
    ]},[_c('div',{staticClass:"opening-hours"},[(_vm.fakeHours && Object.keys(_vm.fakeHours).length > 0
          && _vm.fakeHoursManned && Object.keys(_vm.fakeHoursManned).length > 0)?_c('div',{key:`weekday-day--1`,staticClass:"hours hours-headline"},[_vm._v(" Ohne Verkaufspersonal ")]):_vm._e(),_vm._l((_vm.fakeHours),function(days,weekday){return _c('div',{key:`weekday-day-${weekday}`,staticClass:"hours"},[_c('span',{staticClass:"weekday-name"},[_vm._v(_vm._s(_vm.weekdayName(Number(weekday))))]),_c('span',{staticClass:"two-po"},[_vm._v(":")]),_vm._l((days),function(day,index){return [(_vm.$date(day.timeFrom, 'HH:mm:ss').format('HH:mm') === '00:00' &&
              _vm.$date(day.timeTo, 'HH:mm:ss').format('HH:mm') === '00:00')?_c('span',{key:`hour-${day.id}`},[_c('span',{staticClass:"without-employee"},[_vm._v("Ohne Personal")])]):_c('span',{key:`hour-else-${day.id}`},[_c('span',{class:index === 0 ? 'first-ho' : 'ho'},[_vm._v(" "+_vm._s(_vm.$date(day.timeFrom, 'HH:mm:ss').format('HH:mm'))+" - "+_vm._s(_vm.$date(day.timeTo, 'HH:mm:ss').format('HH:mm')))]),(index < days.length - 1)?_c('br'):_vm._e()])]})],2)})],2)]):_vm._e(),(_vm.boxOpened.status === 'CLOSED'
      && _vm.fakeHours && Object.keys(_vm.fakeHours).length === 0
        && _vm.fakeHoursManned && Object.keys(_vm.fakeHoursManned).length === 0)?_c('div',{staticClass:"closed"},[_c('div',{staticClass:"opening-hours"},_vm._l((_vm.boxOpeningHoursGrouped),function(days,weekday){return _c('div',{key:`weekday-day-${weekday}`,staticClass:"hours"},[_c('span',{staticClass:"weekday-name"},[_vm._v(_vm._s(_vm.weekdayName(Number(weekday))))]),_c('span',{staticClass:"two-po"},[_vm._v(":")]),_vm._l((days),function(day,index){return [(_vm.$date(day.timeFrom, 'HH:mm:ss').format('HH:mm') === '00:00' &&
                      _vm.$date(day.timeTo, 'HH:mm:ss').format('HH:mm') === '00:00')?_c('span',{key:day.id},[_c('span',{staticClass:"without-employee"},[_vm._v("Ohne Personal")])]):_c('span',{key:`else-${day.id}`},[_c('span',{class:index === 0 ? 'first-ho' : 'ho'},[_vm._v(" "+_vm._s(_vm.$date(day.timeFrom, 'HH:mm:ss').format('HH:mm'))+" - "+_vm._s(_vm.$date(day.timeTo, 'HH:mm:ss').format('HH:mm')))]),(index < days.length - 1)?_c('br'):_vm._e()])]})],2)}),0)]):_vm._e(),(_vm.boxOpened.status !== 'OPEN' && _vm.boxOpened.status !== 'CLOSED')?_c('div',{staticClass:"closed"},[(_vm.boxOpened.status === 'MAINTENANCE')?_c('div',{staticClass:"maintenance"},[_c('h4',[_vm._v("CLOSED - Wartung")])]):_vm._e(),(_vm.boxOpened.status === 'DISTURBANCE')?_c('div',{staticClass:"disturbance"},[_c('h4',[_vm._v("CLOSED - Störung")])]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }