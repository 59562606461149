<template>
  <div class="box-status">
    <!-- index:main:isOpen START -->
    <span
      style="font-size: 24px; margin-top: 1.5rem;"
      v-html="'AppCheckin - SmsCheckin - CardCheckin - NfcCheckin'"
      v-if="boxOpened.isOpen && false"></span>

    <div class="description" v-if="boxOpened.isOpen">
      <!-- eslint-disable-next-line max-len -->
      <span v-html="$t(`outdoor.index.combi.headline.${box.hasAppCheckin}_${box.hasSmsCheckin}_${box.hasCardCheckin}_${box.hasNfcCheckin}`)"></span>
    </div>

    <div class="description" v-if="boxOpened.status === 'CLOSED'">
      <!-- eslint-disable-next-line max-len -->
      <span v-html="`<strong>Wir haben gerade geschlossen!</strong>Bitte besuchen Sie unswährend <br>unserer Öffnungszeiten:`"></span>
    </div>

    <div
      v-if="boxOpened.status === 'OPEN'
        && fakeHoursManned && Object.keys(fakeHoursManned).length > 0"
      class="opening-hours opened"
    >
      <div
        class="hours hours-headline"
        :key="`weekday-day--1`"
        v-if="fakeHours && Object.keys(fakeHours).length > 0
          && fakeHoursManned && Object.keys(fakeHoursManned).length > 0"
      >
        Mit Verkaufspersonal
      </div>
      <div
        class="hours"
        v-for="(days, weekday) in fakeHoursManned"
        :key="`weekday-day-${weekday}`"
      >
        <span class="weekday-name">{{ weekdayName(Number(weekday)) }}</span>
        <span class="two-po">:</span>

        <template v-for="(day, index) in days">
          <span
            :key="`hour-${day.id}`"
            v-if="$date(day.timeFrom, 'HH:mm:ss').format('HH:mm') === '00:00' &&
              $date(day.timeTo, 'HH:mm:ss').format('HH:mm') === '00:00'">
            <span class="without-employee">Ohne Personal</span>
          </span>

          <span
            v-else
            :key="`hour-else-${day.id}`">
            <!-- eslint-disable-next-line max-len -->
            <span :class="index === 0 ? 'first-ho' : 'ho'">
            <!-- eslint-disable-next-line max-len -->
            {{$date(day.timeFrom, 'HH:mm:ss').format('HH:mm')}} - {{$date(day.timeTo, 'HH:mm:ss').format('HH:mm')}}</span>
            <br v-if="index < days.length - 1">
          </span>
        </template>
        <!-- <span
          v-if="$date(day.timeFrom, 'HH:mm:ss').format('HH:mm') === '00:00' &&
            $date(day.timeTo, 'HH:mm:ss').format('HH:mm') === '00:00'"
          v-html="`
          <span class='weekday-name'>${weekdayName(day.weekday)}</span><span class='two-po'>:</span>
          <span class='without-employee'>Ohne Personal</span>`"
        ></span>

        eslint-disable-next-line max-len
        <span
          v-if="$date(day.timeFrom, 'HH:mm:ss').format('HH:mm') !== '00:00' &&
            $date(day.timeTo, 'HH:mm:ss').format('HH:mm') !== '00:00'"
          v-html="`
          <span class='weekday-name'>${weekdayName(day.weekday)}</span><span class='two-po'>:</span>
          <span class='ho'>${$date(day.timeFrom, 'HH:mm:ss').format('HH:mm')}
            - ${$date(day.timeTo, 'HH:mm:ss').format('HH:mm')}</span>`"
        ></span> -->
      </div>
    </div>

    <div v-if="boxOpened.status === 'OPEN' && fakeHours && Object.keys(fakeHours).length > 0"
      :class="[
        'opening-hours opened',
        { 'opened-second' : fakeHoursManned && Object.keys(fakeHoursManned).length > 0 }
      ]"
    >
      <div
        class="hours hours-headline"
        :key="`weekday-day--1`"
        v-if="fakeHours && Object.keys(fakeHours).length > 0
          && fakeHoursManned && Object.keys(fakeHoursManned).length > 0"
      >
        Ohne Verkaufspersonal
      </div>
      <div
        class="hours"
        v-for="(days, weekday) in fakeHours"
        :key="`weekday-day-${weekday}`"
      >
        <span class="weekday-name">{{ weekdayName(Number(weekday)) }}</span>
        <span class="two-po">:</span>

        <template v-for="(day, index) in days">
          <span
            :key="`hour-${day.id}`"
            v-if="$date(day.timeFrom, 'HH:mm:ss').format('HH:mm') === '00:00' &&
              $date(day.timeTo, 'HH:mm:ss').format('HH:mm') === '00:00'">
            <span class="without-employee">Ohne Personal</span>
          </span>

          <span
            v-else
            :key="`hour-else-${day.id}`">
            <!-- eslint-disable-next-line max-len -->
            <span :class="index === 0 ? 'first-ho' : 'ho'">
            <!-- eslint-disable-next-line max-len -->
            {{$date(day.timeFrom, 'HH:mm:ss').format('HH:mm')}} - {{$date(day.timeTo, 'HH:mm:ss').format('HH:mm')}}</span>
            <br v-if="index < days.length - 1">
          </span>
        </template>
        <!-- eslint-disable-next-line max-len -->
        <!-- <span v-html="`<span class='weekday-name'>${weekdayName(day.weekday)}</span><span class='two-po'>:</span>
          <span class='ho'>${$date(day.timeFrom, 'HH:mm:ss').format('HH:mm')}
            - ${$date(day.timeTo, 'HH:mm:ss').format('HH:mm')}</span>`"
        ></span> -->
      </div>
    </div>

    <!-- eslint-disable-next-line max-len -->
    <div v-if="boxOpened.status === 'OPEN' && fakeHours && Object.keys(fakeHours).length === 0 && fakeHoursManned && Object.keys(fakeHoursManned).length === 0" class="opening-hours opened">
      <div
        class="hours"
        v-for="(days, weekday) in boxOpeningHoursGrouped"
        :key="`weekday-day-${weekday}`"
      >
        <span class="weekday-name">{{ weekdayName(Number(weekday)) }}</span>
        <span class="two-po">:</span>

        <template v-for="(day, index) in days">
          <span :key="day.id" v-if="$date(day.timeFrom, 'HH:mm:ss').format('HH:mm') === '00:00' &&
                      $date(day.timeTo, 'HH:mm:ss').format('HH:mm') === '00:00'">
            <span class="without-employee">Ohne Personal</span>
          </span>
          <span :key="`else-${day.id}`" v-else>
            <!-- eslint-disable-next-line max-len -->
            <span :class="index === 0 ? 'first-ho' : 'ho'">
            <!-- eslint-disable-next-line max-len -->
            {{$date(day.timeFrom, 'HH:mm:ss').format('HH:mm')}} - {{$date(day.timeTo, 'HH:mm:ss').format('HH:mm')}}</span>
            <br v-if="index < days.length - 1">
          </span>
        </template>
        <!-- eslint-disable-next-line max-len -->
        <!-- <span v-html="`<span class='weekday-name'>${weekdayName(day.weekday)}</span><span class='two-po'>:</span>
          <span class='ho'>${$date(day.timeFrom, 'HH:mm:ss').format('HH:mm')}
            - ${$date(day.timeTo, 'HH:mm:ss').format('HH:mm')}</span>`"
        ></span> -->
      </div>
    </div>
    <!-- index:main:isOpen END -->

    <!-- index:main:isClosed START -->
    <div v-if="boxOpened.status === 'CLOSED'
      && fakeHoursManned && Object.keys(fakeHoursManned).length > 0"
      class="closed">
      <!-- eslint-disable-next-line max-len -->
      <div class="opening-hours">
        <div
          class="hours hours-headline"
          :key="`weekday-day--1`"
          v-if="fakeHours && fakeHours.length > 0
            && fakeHoursManned && Object.keys(fakeHoursManned).length > 0"
        >
          Mit Verkaufspersonal
        </div>
        <div
          class="hours"
          v-for="(days, weekday) in fakeHoursManned"
          :key="`weekday-day-${weekday}`"
        >
          <span class="weekday-name">{{ weekdayName(Number(weekday)) }}</span>
          <span class="two-po">:</span>

          <template v-for="(day, index) in days">
            <span
              :key="`hour-${day.id}`"
              v-if="$date(day.timeFrom, 'HH:mm:ss').format('HH:mm') === '00:00' &&
                $date(day.timeTo, 'HH:mm:ss').format('HH:mm') === '00:00'">
              <span class="without-employee">Ohne Personal</span>
            </span>

            <span
              v-else
              :key="`hour-else-${day.id}`">
              <!-- eslint-disable-next-line max-len -->
              <span :class="index === 0 ? 'first-ho' : 'ho'">
            <!-- eslint-disable-next-line max-len -->
            {{$date(day.timeFrom, 'HH:mm:ss').format('HH:mm')}} - {{$date(day.timeTo, 'HH:mm:ss').format('HH:mm')}}</span>
              <br v-if="index < days.length - 1">
            </span>
          </template>
          <!-- eslint-disable-next-line max-len -->
          <!-- <span v-html="`<span class='weekday-name'>${weekdayName(day.weekday)}</span><span class='two-po'>:</span>
            <span class='ho'>${$date(day.timeFrom, 'HH:mm:ss').format('HH:mm')}
             - ${$date(day.timeTo, 'HH:mm:ss').format('HH:mm')}</span>`"
          ></span> -->
        </div>
      </div>
    </div>

    <div v-if="boxOpened.status === 'CLOSED'
      && fakeHours && Object.keys(fakeHours).length > 0"
      :class="[
        'closed',
        { 'closed-second' : fakeHoursManned && Object.keys(fakeHoursManned).length > 0 }
      ]"
    >
      <!-- eslint-disable-next-line max-len -->
      <div class="opening-hours">
        <div
          class="hours hours-headline"
          :key="`weekday-day--1`"
          v-if="fakeHours && Object.keys(fakeHours).length > 0
            && fakeHoursManned && Object.keys(fakeHoursManned).length > 0"
        >
          Ohne Verkaufspersonal
        </div>
        <div
          class="hours"
          v-for="(days, weekday) in fakeHours"
          :key="`weekday-day-${weekday}`"
        >
          <span class="weekday-name">{{ weekdayName(Number(weekday)) }}</span>
          <span class="two-po">:</span>

          <template v-for="(day, index) in days">
            <span
              :key="`hour-${day.id}`"
              v-if="$date(day.timeFrom, 'HH:mm:ss').format('HH:mm') === '00:00' &&
                $date(day.timeTo, 'HH:mm:ss').format('HH:mm') === '00:00'">
              <span class="without-employee">Ohne Personal</span>
            </span>

            <span
              v-else
              :key="`hour-else-${day.id}`">
              <!-- eslint-disable-next-line max-len -->
              <span :class="index === 0 ? 'first-ho' : 'ho'">
            <!-- eslint-disable-next-line max-len -->
            {{$date(day.timeFrom, 'HH:mm:ss').format('HH:mm')}} - {{$date(day.timeTo, 'HH:mm:ss').format('HH:mm')}}</span>
              <br v-if="index < days.length - 1">
            </span>
          </template>
          <!-- eslint-disable-next-line max-len -->
          <!-- <span v-html="`<span class='weekday-name'>${weekdayName(day.weekday)}</span><span class='two-po'>:</span>
            <span class='ho'>${$date(day.timeFrom, 'HH:mm:ss').format('HH:mm')}
             - ${$date(day.timeTo, 'HH:mm:ss').format('HH:mm')}</span>`"
          ></span> -->
        </div>
      </div>
    </div>

    <div class="closed" v-if="boxOpened.status === 'CLOSED'
        && fakeHours && Object.keys(fakeHours).length === 0
          && fakeHoursManned && Object.keys(fakeHoursManned).length === 0">
      <!-- index:main:isClosed:times START -->
      <div class="opening-hours">
        <div
          class="hours"
          v-for="(days, weekday) in boxOpeningHoursGrouped"
          :key="`weekday-day-${weekday}`"
        >
          <span class="weekday-name">{{ weekdayName(Number(weekday)) }}</span>
          <span class="two-po">:</span>

          <template v-for="(day, index) in days">
            <span :key="day.id" v-if="$date(day.timeFrom, 'HH:mm:ss').format('HH:mm') === '00:00' &&
                        $date(day.timeTo, 'HH:mm:ss').format('HH:mm') === '00:00'">
              <span class="without-employee">Ohne Personal</span>
            </span>
            <span :key="`else-${day.id}`" v-else>
              <!-- eslint-disable-next-line max-len -->
              <span :class="index === 0 ? 'first-ho' : 'ho'">
            <!-- eslint-disable-next-line max-len -->
            {{$date(day.timeFrom, 'HH:mm:ss').format('HH:mm')}} - {{$date(day.timeTo, 'HH:mm:ss').format('HH:mm')}}</span>
              <br v-if="index < days.length - 1">
            </span>
          </template>
          <!-- eslint-disable-next-line max-len -->
          <!-- <span v-html="`<span class='weekday-name'>${weekdayName(day.weekday)}</span><span class='two-po'>:</span>
            <span class='ho'>${$date(day.timeFrom, 'HH:mm:ss').format('HH:mm')}
              - ${$date(day.timeTo, 'HH:mm:ss').format('HH:mm')}</span>`"
          ></span> -->
        </div>
      </div>
      <!-- index:main:isClosed:times END -->
    </div>

    <div class="closed" v-if="boxOpened.status !== 'OPEN' && boxOpened.status !== 'CLOSED'">
      <!-- index:main:isClosed:maintenance START -->
      <div
        class="maintenance"
        v-if="boxOpened.status === 'MAINTENANCE'"
      >
        <h4>CLOSED - Wartung</h4>
      </div>
      <!-- index:main:isClosed:maintenance END -->

      <!-- index:main:isClosed:disturbance START -->
      <div
        class="disturbance"
        v-if="boxOpened.status === 'DISTURBANCE'"
      >
        <h4>CLOSED - Störung</h4>
      </div>
      <!-- index:main:isClosed:disturbance END -->

    </div>
    <!-- index:main:isClosed END -->
  </div>
</template>

<script>
export default {
  name: 'box-status',
  props: {
    box: {
      type: Object,
    },
    fakeHours: {
      type: Object,
    },
    fakeHoursManned: {
      type: Object,
    },
    boxOpened: {
      type: Object,
    },
  },
  data() {
    return {
      boxOpeningHoursSorted: null,
    };
  },
  created() {
    const { openingHours } = this.box;
    this.boxOpeningHoursGrouped = this.groupBoxOpeningHours(openingHours);
  },
  methods: {
    groupBoxOpeningHours(openingHours) {
      // Sort opening hours first
      const sortedHours = openingHours.sort(this.compare);

      // Group hours by weekday
      return sortedHours.reduce((acc, day) => {
        const dayKey = day.weekday;
        if (!acc[dayKey]) {
          acc[dayKey] = [];
        }
        acc[dayKey].push(day);
        return acc;
      }, {});
    },

    weekdayName(weekdayNumber) {
      const days = [
        { weekday: 1, name: `${this.$t('date.sunday')}` },
        { weekday: 2, name: `${this.$t('date.monday')}` },
        { weekday: 3, name: `${this.$t('date.tuesday')}` },
        { weekday: 4, name: `${this.$t('date.wednesday')}` },
        { weekday: 5, name: `${this.$t('date.thursday')}` },
        { weekday: 6, name: `${this.$t('date.friday')}` },
        { weekday: 7, name: `${this.$t('date.saturday')}` },
      ];

      // eslint-disable-next-line no-shadow
      const day = days.find((day) => day.weekday === weekdayNumber);
      return day ? day.name : ''; // Return name or empty string if not found
    },

    compare(a, b) {
      if (a.weekday < b.weekday) {
        return -1;
      }
      if (a.weekday > b.weekday) {
        return 1;
      }
      return 0;
    },
  },
};
</script>
